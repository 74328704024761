<template>   
    <ContasPagarResumido v-if="!showDetalhe" v-on:showDetail="showDetail" 
            :matricula="currentUser.matricula" 
            :safra="safra" 
            :cultura="cultura" />
    <ContasPagarDetalhado v-if="showDetalhe" v-on:showDetail="showDetail" 
            :matricula="currentUser.matricula"
            :especie="especie"
            :unidade-negocio="unidadeNegocio" 
            :safra="safra" 
            :cultura="cultura" />
</template>

<script lang="js">
    import ContasPagarResumido from '../../components/extrato/ContasPagarResumido.vue';
    import ContasPagarDetalhado from '../../components/extrato/ContasPagarDetalhado.vue';

    export  default {
        data(){
            return {
                showDetalhe: false,
                especie: null,
                unidadeNegocio: null,
                safra: {},
                cultura: {},
            }
        },
        props: {
            conta: Object
        },
        methods: {
            showDetail(value, especie, unidadeNegocio, safra, cultura) {
                this.showDetalhe = value;
                if (value) {
                    this.especie = especie;
                    this.unidadeNegocio = unidadeNegocio;
                }
                this.safra = safra;
                this.cultura = cultura;
            }
        },
		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { ContasPagarResumido, ContasPagarDetalhado }
    }

</script>
<style lang="sass" scoped>
    
</style>