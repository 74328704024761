<template>
    <div class="col-12">
        <div class="card">
            <h5>Extrato Detalhado Contas a Pagar</h5>
			<DataTable :value="contasPagar" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
					:globalFilterFields="[
						'nomeEstabelecimento', 
						'dataEmissaoDocumento', 
						'dataVencimento', 
						'codigoEspecie', 
						'codigoUnidadeNegocio', 
						'especie', 
						'unidadeNegocio', 
						'valorOriginal', 
						'valorSaldo'
					]" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<div>
							<Button @click="$emit('showDetail', false, especie, unidadeNegocio, safra, cultura)" 
									icon="pi pi-backward" 
									type="button" 
									class="p-button-outlined mb-2 mr-2" 
									label="Voltar"/>
						</div>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando... Por favor, aguarde.
				</template>
				<Column field="nomeEstabelecimento" header="Integrada" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						{{data.data.nomeEstabelecimento}}
					</template>
				</Column>
				<Column field="dataEmissaoDocumento" header="Emissão" :sortable="true" style="text-align: center; min-width:6rem" />
				<Column field="dataVencimento" header="Vencimento" :sortable="true" style="text-align: center; min-width:6rem" />
				<Column field="especie" header="Espécie" :sortable="true" style="text-align: center; min-width:6rem">
					<template #body="data">
						{{data.data.especie}}
					</template>
				</Column>
				<Column field="unidadeNegocio" header="Unid. negócio" :sortable="true" style="text-align: center; min-width:12rem">
					<template #body="data">
						<div style="text-align: left;">
							{{unidadeNegocio}} - {{ data.data.unidadeNegocio }}
						</div>
					</template>
				</Column>
				<Column field="valorOriginal" header="Total" :sortable="true" style="text-align: right; min-width:6rem">
					<template #body="data">
						<span class="text-bold label-blue">{{formatCurrency(data.data.valorOriginal)}}</span>
					</template>
				</Column>
				<Column field="valorSaldo" header="Saldo" :sortable="true" style="text-align: right; min-width:6rem">
					<template #body="data">
						<span class="text-bold label-red">{{formatCurrency(data.data.valorSaldo)}}</span>
					</template>
				</Column>

				<ColumnGroup type="footer">
					<Row>
						<Column footer="Total:" :colspan="5" footerStyle="text-align: right;"/>
						<Column :footer="formatCurrency(totalGeral)" footerStyle="text-align: right;"/>
						<Column :footer="formatCurrency(totalSaldo)" footerStyle="text-align: right;"/>
					</Row>
				</ColumnGroup>
			</DataTable>
            
        </div>
    </div>

</template>

<script>
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import ContasPagarService from "../../service/ContasPagarService"
	import Formatacao from '../../utilities/Formatacao';


    export default {
        name: 'ContasPagarDetalhado', 

		props: {
			matricula: {
				type: Number,
				required: true
			},
			especie: {
				type: String,
				required: true
			},
			unidadeNegocio: {
				type: String,
				required: true
			},
			safra: {
				type: Object,
				required: false,
			},
			cultura: {
				type: Object,
				required: false,
			},
		},
        data(){
            return {
				filters1: null,
				filters2: {},
				loading1: true,
				loading2: true,
                contasPagar: [],
				totalGeral: null,
				totalSaldo: null
            }
        },

        created(){
            this.initFilters1();
            this.getContasAPagar();
        },
		mounted() {
			this.loading1 = false;
			this.loading2 = false;
		},
        methods: {
            initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
            getContasAPagar() {
                ContasPagarService.getContasAPagarDetalhado(
						this.$props.matricula,
						this.$props.especie,
						this.$props.unidadeNegocio, 
						this.$props.safra.safraComposta, 
						this.$props.cultura.codigo)
					.then((response) => {                    
						this.contasPagar = response.data.detalhes;

						this.contasPagar.map(c => {
							c.dataEmissaoDocumento = this.formatDate(c.dataEmissaoDocumento);
							c.dataVencimento = this.formatDate(c.dataVencimento);
						});

						this.totalGeral = response.data.totalGeral;
						this.totalSaldo = response.data.totalSaldo;
                }) ;
            },
			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},
			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			}

        }
       
    }
    
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

    button{
		margin-right: 8px;
	}

	.label-blue{
		color: #0066cc;
	}
	.label-red{
		color: #B40404;
	}
	.p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>
