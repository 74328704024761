import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CONTAS_PAGAR_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/conta-a-pagar`;

class ContasPagarService {

    getContasAPagarResumido(matricula, safra, cultura) {
        let params = {
            safra: safra,
            cultura: cultura
        }
        return axios.get(CONTAS_PAGAR_API_BASE_URL + RESOURCE_V1 +"/resumido/"+ matricula, {params});
    }

    getContasAPagarDetalhado(matricula, codigoEspecie, codigoUnidadeNegocio, safra, cultura) {
        let params = {
            safra: safra, 
            cultura: cultura,
        }
        return axios.get(CONTAS_PAGAR_API_BASE_URL + RESOURCE_V1 + "/detalhado/" 
        +matricula + "/" + codigoEspecie + "/" + codigoUnidadeNegocio, {params});
    }
}

export default new ContasPagarService();
