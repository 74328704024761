<template>
    <div class="card">
        <h5>Contas a Pagar</h5>			

        <DataTable :value="contasPagar" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
                v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1" responsiveLayout="stack"
                :globalFilterFields="['codigoEspecie','codigoUnidadeNegocio','especie','unidadeNegocio','valorOriginal','valorSaldo']" >
            <template #header>
                <div class="flex flex-column sm:flex-row">
                    <Dropdown class="mb-2" 
                            placeholder="Safra" 
                            id="safra" 
                            v-model="safraSelecionada" 
                            :options="safras" 
                            optionLabel="descricao" 
                            style="width: 10rem;" />
                    <Dropdown class="ml-3 mb-2" 
                            placeholder="Cultura" 
                            id="cultura" 
                            v-model="culturaSelecionada" 
                            :options="culturas" 
                            optionLabel="descricao" 
                            style="width: 10rem;" />
                    <Button label="Consultar" 
                            @click="getContasAPagarResumido" 
                            class="mb-2 ml-3" 
                            icon="pi pi-search"></Button>
                    <Button type="button" 
                            icon="pi pi-filter-slash" 
                            label="Limpar" 
                            class="p-button-outlined mb-2 ml-3" 
                            @click="clearFilter1()" />
                </div>
            </template>
            <template #empty>
                Nenhum registro encontrado.
            </template>
            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column style="text-align: center; min-width:2rem">
                <template #body="data">
                    <Button @click="$emit('showDetail', true, data.data.codigoEspecie, data.data.codigoUnidadeNegocio, safraSelecionada, culturaSelecionada)" 
                            type="button" class="mr-2" label="Detalhar" icon="pi pi-search"></Button>
                </template>
            </Column>

            <Column field="codigoEspecie" header="Espécie" :sortable="true" style="text-align: center;">
                <template #body="data">
                    {{data.data.codigoEspecie}} - {{data.data.especie}}
                </template>
            </Column>

            <Column field="codigoUnidadeNegocio" header="Unid. negócio" :sortable="true" style="text-align: center;">
                <template #body="data">
                    <div style="text-align: left;">
                        {{data.data.codigoUnidadeNegocio}} - {{ data.data.unidadeNegocio }}
                    </div>
                </template>
            </Column>

            <Column field="valorOriginal" header="Total" :sortable="true" style="text-align: right;">
                <template #body="data">
                    <span class="text-bold label-blue">{{formatCurrency(data.data.valorOriginal)}}</span>
                </template>
            </Column>

            <Column field="valorSaldo" header="Saldo" :sortable="true" style="text-align: right;">
                <template #body="data">
                    <span class="text-bold label-red">{{formatCurrency(data.data.valorSaldo)}}</span>
                </template>
            </Column>
            <ColumnGroup type="footer">
                <Row>
                    <Column footer="Total:" :colspan="3" footerStyle="text-align:right"/>
                    <Column :footer="formatCurrency(totalGeral)" footerStyle="text-align: right; color: #0066cc"/>
                    <Column :footer="formatCurrency(totalSaldo)" footerStyle="text-align: right; color: #B40404" />
                </Row>
            </ColumnGroup>
        </DataTable>
        
    </div>
</template>

<script>
    import {FilterMatchMode,FilterOperator} from 'primevue/api';
    import ContasPagarService from "../../service/ContasPagarService";
    import Formatacao from '../../utilities/Formatacao';
    import SafraService from '../../service/SafraService';
    import CulturaService from '../../service/CulturaService';

    export default {
        name: 'ContasPagar', 

        props: {
            matricula: {
                type: Number,
                required: true
            },
            safra: {
				type: Object,
				required: false,
			},
			cultura: {
				type: Object,
				required: false,
			},
        },
        data(){
            return {
                filters1: null,
                filters2: {},
                loading1: true,
                loading2: true,
                contasPagar: [],
                totalGeral: null,
                totalSaldo: null,
                safraSelecionada: this.safra,
                safras: [],
                culturaSelecionada: this.cultura,
                culturas: [],
            }
        },

        created() {
            this.initFilters1();
            this.getContasAPagarResumido();
            this.buscarSafras();
            this.buscarCulturas();
        },
        mounted() {
            this.loading1 = false;
            this.loading2 = false;
        },
        methods: {
            initFilters1() {
                this.filters1 = {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'codigoEspecie': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'codigoUnidadeNegocio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'especie': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'unidadeNegocio': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'valorOriginal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
                    'valorSaldo': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},				
                }
            },
            getContasAPagarResumido() {
                if (!this.safraSelecionada || typeof this.safraSelecionada == 'string') {
                    this.safraSelecionada = this.safras.length > 0 ? this.safras[0] : null;
                }
                if (!this.culturaSelecionada || typeof this.culturaSelecionada == 'string') {
                    this.culturaSelecionada = this.culturas.length > 0 ? this.culturas[0] : null;
                }

                ContasPagarService.getContasAPagarResumido(this.$props.matricula, 
                        this.safraSelecionada.safraComposta, this.culturaSelecionada.codigo)
                .then((response) => {
                    this.contasPagar = response.data.detalhes;
                    this.totalGeral = response.data.totalGeral;
                    this.totalSaldo = response.data.totalSaldo;
                }) ;
            },
            clearFilter1() {
                this.safraSelecionada = {};
                this.culturaSelecionada = {};
                this.initFilters1();
            },
            formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
            },
            
            buscarSafras() {
                SafraService.getSafras()
                        .then( ({data}) => {
                            this.safras = data;

                            this.safras.unshift({
                                codigo: "",
                                descricao: "Todas"
                            });
                        });
            },

            buscarCulturas() {
                CulturaService.getCulturas().then(({ data }) => {
                    if (data) {
                        this.culturas = data;
                    }

                    this.culturas.unshift({
                        codigo: "",
                        descricao: "Todas"
                    });
                });
            },
        }
    }
    
</script>

<style>
    @import '../../assets/demo/styles/badges.scss';

    .label-blue{
        color: #0066cc;
    }
    .label-red{
        color: #B40404;
    }
    .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }

</style>
